import { Router } from '@angular/router';
import { PaymentService } from './../../../services/payment.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, OnDestroy, HostListener, ViewChild, ElementRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { pageTitles } from 'src/app/PageTitles';
import { trigger, state, style, transition, animate } from '@angular/animations';

declare const window: any;

@Component({
  selector: 'app-fashion-checkout',
  templateUrl: './fashion-checkout.component.html',
  styleUrls: ['./fashion-checkout.component.less'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [
        style({ opacity: 0 }),
        animate(500)
      ]),
      transition(':leave',
        animate(500, style({ opacity: 0 })))
    ])
  ]
})
export class FashionCheckoutComponent implements OnInit, OnDestroy {

  gutterSize = 16;
  colSize = 8;
  isIFrameModalVisible = false;
  showBackdrop = false;
  showInnerLoader = true;
  scriptPath = 'assets/iframe_sdk.js';
  private breakPointSubscription: Subscription;
  @HostListener('window:message', ['$event'])
  onMessage(event: any): void {
    const dataArray = event.data.split(' ');

    if (dataArray[0] === 'fail') {
      this.router.navigate(['/fail'], { queryParams: { txnRef: dataArray[1] } });
    }
    else if (dataArray[0] === 'success') {
      this.router.navigate(['/success'], { queryParams: { txnRef: dataArray[1] } });
    }
  }

  constructor(
    private breakpointObserver: BreakpointObserver,
    private titleService: Title,
    private paymentService: PaymentService,
    private router: Router
  ) {
    this.breakPointSubscription = this.breakpointObserver.observe('(max-width: 1024px)').subscribe(result => {
      this.gutterSize = result.matches ? 16 : 48;
      this.colSize = result.matches ? 24 : 8;
    });
  }

  ngOnInit(): void {
    this.titleService.setTitle(pageTitles.checkout);
    this.loadScript();
  }

  ngOnDestroy(): void {
    this.unsubscribe(this.breakPointSubscription);
  }

  unsubscribe(subscription: Subscription): void {
    if (subscription) {
      subscription.unsubscribe();
    }
  }

  loadScript(): void {
    const body = document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = this.scriptPath;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

  closeModal(): void {
    this.isIFrameModalVisible = false;
  }

  checkoutPayment(eventObject: any): void {
    // console.log(eventObject);
    this.showBackdrop = true;
    this.paymentService.doPayment({ ...eventObject, apiType: 'iframeWHPP' }).subscribe(response => {
      // console.log(response);
      this.showBackdrop = false;
      this.isIFrameModalVisible = true;
      setTimeout(() => {
        window.__ngpIFrame.initPaymentIFrame('modalIFrame', response.value, response.action);
      }, 500);
    }, error => {
      // console.log(error);
    });
  }

  loadedIframe(): void {
    // console.log('iframe loaded');
  }
}
