import { Router } from '@angular/router';
import { PaymentService } from './../../../services/payment.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { pageTitles } from 'src/app/PageTitles';
import { OrderService } from 'src/app/services/checkout.service';


@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.less']
})
export class CheckoutComponent implements OnInit, OnDestroy {

  gutterSize = 16;
  colSize = 8;
  showBackdrop = false;
  showInnerLoader = true;
  showSuccessLoader = false;
  showFailLoader = false;
  private breakPointSubscription: Subscription;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private titleService: Title,
    private paymentService: PaymentService,
    private router: Router
  ) {
    this.breakPointSubscription = this.breakpointObserver.observe('(max-width: 1024px)').subscribe(result => {
      this.gutterSize = result.matches ? 16 : 48;
      this.colSize = result.matches ? 24 : 8;
    });
  }
  unsubscribe(subscription: Subscription): void {
    if (subscription) {
      subscription.unsubscribe();
    }
  }
  ngOnInit(): void {
    this.titleService.setTitle(pageTitles.checkout);
  }

  ngOnDestroy(): void {
    this.unsubscribe(this.breakPointSubscription);
  }

  checkoutPayment(eventObject: any): void {
    this.showBackdrop = true;
    this.paymentService.doPayment({ ...eventObject, apiType: 'sync' }).subscribe(response => {

      if (response.response) {
        response = response.response;
        const txnRef = response.txnReference;
        const status = response.OriginalTxnStatusCode;
        if (status === 200 || status === 201) {
          this.router.navigate(['/success'], { queryParams: { txnRef } });
        }
      }
      else {
        // console.log(response);
      }
    }, errorRes => {
      // console.log(errorRes);
    });
  }
}
