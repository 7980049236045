import { CurrencyService } from './../services/currency.service';
import { Order } from 'src/app/services/checkout.service';
import { Subscription } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaymentService } from 'src/app/services/payment.service';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.less']
})
export class SuccessComponent implements OnInit, OnDestroy {

  colSize = 12;
  breakPointSubscription: Subscription;
  breakPointsObj = {
    sm: '(max-width:767px)',
    md: '(min-width:768px)',
    lg: '(min-width:1024px)'
  };
  showLoader = false;
  txnRef!: any;
  date!: any;
  time!: any;
  orderObject!: Order;
  addressObject!: any;
  currency: any;

  constructor(
    private breakPointObserver: BreakpointObserver,
    private activatedRoute: ActivatedRoute,
    private paymentService: PaymentService,
    private currencyService: CurrencyService,
  ) {
    this.breakPointSubscription = this.breakPointObserver.observe([
      this.breakPointsObj.sm,
      this.breakPointsObj.md,
      this.breakPointsObj.lg
    ]).subscribe(result => {
      if (result.breakpoints[this.breakPointsObj.sm]) {
        this.colSize = 24;
      }
      else if (result.breakpoints[this.breakPointsObj.md] && !result.breakpoints[this.breakPointsObj.lg]) {
        this.colSize = 24;
      }
      else if (result.breakpoints[this.breakPointsObj.lg]) {
        this.colSize = 12;
      }
    });

  }

  ngOnInit(): void {
    setTimeout(() => this.showLoader = true, 400);
    // console.log(this.activatedRoute.snapshot.queryParams.txnRef);
    if (this.activatedRoute.snapshot.queryParams && this.activatedRoute.snapshot.queryParams.txnRef) {
      this.txnRef = this.activatedRoute.snapshot.queryParams.txnRef;
    }
    // console.log(this.txnRef);
    const object: any = localStorage.getItem('orderObject');
    this.orderObject = JSON.parse(object);

    const address: any = localStorage.getItem('addressObject');
    this.addressObject = JSON.parse(address);

    // console.log(this.orderObject);
    this.paymentService.getTransactionStatus(this.txnRef).subscribe(response => {
      // console.log(response);
      let transactionDate = response.transactionDate;
      transactionDate = transactionDate.split(' ');
      this.date = transactionDate[0];
      this.time = transactionDate[1];
    },
      error => {
        // console.log(error);
      });
    this.currency = this.currencyService.selectedCurrency;
  }

  ngOnDestroy(): void {
    if (this.breakPointSubscription) {
      this.breakPointSubscription.unsubscribe();
    }
    localStorage.setItem('successAllowed', 'false');
  }
}
