<div [style]="{'padding': '0' + ( gutterSize / 2 ) + 'px'}">
  <div nz-row [nzGutter]="gutterSize" nzAlign="middle">
    <div nz-col class="gutter-row" [nzSpan]="colSize">
      <app-gallery [images]="productImages"></app-gallery>
    </div>
    <div nz-col class="gutter-row second-col" [nzSpan]="colSize">
      <h2 class="h2-responsive mb-0">
        <strong [style]="{'color':selectedColor}"> Roses </strong>
      </h2>
      <h5 class="h5-responsive mb-0" nz-typography nzType="secondary">lorem ipsum dolor</h5>
      <nz-divider></nz-divider>
      <div class="product-price">
        <h4 class="h4-responsive mb-20">
          <strong>
            <nz-select style="font-size:inherit; " nzShowSearch [ngModelOptions]="{standalone: true}" [(ngModel)]="currency" (ngModelChange)="currencyChange()" [nzOptions]="currencyList" nzBorderless></nz-select> {{price | number:'1.2-2'}} </strong>
        </h4>
        <p> <strong> Please select a color. </strong></p>
        <p class="size-btns-container">
          <button (click)="changeColor(color.value)" #flowerColors [style]="{'background-color':color.value,'color':color.value}" nz-button nzType="default" nzShape="circle" class="mr-10" *ngFor="let color of flowerColors">
            &#10003;
          </button>
        </p>
        <p>
          quantity
          <nz-select [(ngModel)]="quantity" [nzOptions]="quantities"></nz-select>
        </p>
        <p>
          <button [ngStyle]="buttonStyle" class="grad-button" (click)="gotoCheckout()" nz-button nzType="primary" [nzSize]="payBtnSize" nzShape="round"><i nz-icon nzType="send" nzTheme="outline"></i>Update Cart</button>
        </p>
        <nz-collapse nzAccordion>
          <nz-collapse-panel [nzHeader]="panels[0].header" [nzActive]="panels[0].active">
            <p>Nothing says romance like a bouquet of roses. We guarantee you that these specially imported roses from France will brighten up any room with a most delicate sweet smell.</p>
          </nz-collapse-panel>
          <nz-collapse-panel [nzHeader]="panels[1].header" [nzActive]="panels[1].active">
            <p>These come in a bouquet of 12 and in 3 different colours- pink, red and orange.</p>
          </nz-collapse-panel>
        </nz-collapse>
      </div>
    </div>
  </div>
</div>

