import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { storePaths } from 'src/app/StorePaths';

@Component({
  selector: 'app-store-type',
  templateUrl: './store-type.component.html',
  styleUrls: ['./store-type.component.less']
})
export class StoreTypeComponent implements OnInit {

  @Input() stripeTheme: 'light' | 'dark' = 'light';
  @Input() storeTypeName = '';
  @Input() imgSrc = '';
  @Input() redirectUrl = '';

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  onClickHandler(): void {
    this.router.navigate(['/', this.redirectUrl]);
  }

}

export interface StoreTypeInterface {
  stripeTheme: 'light' | 'dark';
  storeTypeName: string;
  imgSrc: string;
  redirectUrl: string;
}
