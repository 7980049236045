import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';
import { storePaths } from '../StorePaths';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit, OnDestroy {

  cryptoLink = storePaths.crypto;
  gamingLink = storePaths.gaming;
  fashionLink = storePaths.fashion;
  flowersLink = storePaths.flowers;
  whiteLabelLogo: string;
  buttonColor: string;

  isScreenSm = false;
  contactBtnShow = false;

  private breakPointSubscription: Subscription;
  private contactBtnShowSubscription: Subscription;

  constructor(private breakpointObserver: BreakpointObserver) {
    const whiteLabelConfig = (window as any).whitelabelConfig;
    this.whiteLabelLogo = whiteLabelConfig?.logo || 'assets/logo.svg';
    this.buttonColor = whiteLabelConfig?.buttonColor || 'white';
    this.breakPointSubscription = this.breakpointObserver.observe('(max-width: 1200px)').subscribe(result => {
      this.isScreenSm = result.matches ? true : false;
    });
    this.contactBtnShowSubscription = this.breakpointObserver.observe('(max-width: 600px)').subscribe(result => {
      this.contactBtnShow = result.matches ? false : true;
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.breakPointSubscription.unsubscribe();
  }

}
