import { PaymentService } from 'src/app/services/payment.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, OnDestroy, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { pageTitles } from 'src/app/PageTitles';

@Component({
  selector: 'app-flowers-checkout',
  templateUrl: './flowers-checkout.component.html',
  styleUrls: ['./flowers-checkout.component.less']
})
export class FlowersCheckoutComponent implements OnInit, OnDestroy, AfterViewInit {

  gutterSize = 16;
  colSize = 8;
  actionLink = '';
  responseValue = '';
  showBackdrop = false;
  showInnerLoader = true;
  selectedPaymentType: string;
  private breakPointSubscription: Subscription;
  @ViewChild('hppForm') hppForm!: ElementRef;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private titleService: Title,
    private paymentService: PaymentService
  ) {
    this.selectedPaymentType = '';
    this.breakPointSubscription = this.breakpointObserver.observe('(max-width: 1024px)').subscribe(result => {
      this.gutterSize = result.matches ? 16 : 48;
      this.colSize = result.matches ? 24 : 8;
    });
  }
  unsubscribe(subscription: Subscription): void {
    if (subscription) {
      subscription.unsubscribe();
    }
  }
  ngOnInit(): void {
    this.titleService.setTitle(pageTitles.checkout);
  }

  ngOnDestroy(): void {
    this.unsubscribe(this.breakPointSubscription);
  }

  ngAfterViewInit(): void {
    // console.log(this.hppForm.nativeElement.action);
  }

  changePaymentType(type: string): void {
    this.selectedPaymentType = type;
    this.paymentService.paymentType = type;
    // console.log(this.paymentService);
  }

  checkoutPayment(eventObject: any): void {
    // console.log(eventObject);
    this.showBackdrop = true;
    this.paymentService.prepareRequestObject({
      ...eventObject, apiType: this.selectedPaymentType === 'HPP' ? 'HPP' : 'plugin',
      mode: this.selectedPaymentType,
    });
    this.paymentService.doPayment({
      ...eventObject, apiType: this.selectedPaymentType === 'HPP' ? 'HPP' : 'plugin',
      mode: this.selectedPaymentType,
    }).subscribe(response => {
      this.actionLink = response.action;
      this.responseValue = response.value;
      setTimeout(() => this.hppForm.nativeElement.submit(), 50);
    },
      error => {
        // console.log(error);
      });
  }
}
