import { SuccessGuardService } from './services/success-guard.service';
import { FailGuardService } from './services/fail-guard.service';
import { FailComponent } from './fail/fail.component';
import { SuccessComponent } from './success/success.component';
import { FlowersCheckoutComponent } from './product-pages/flowers/flowers-checkout/flowers-checkout.component';
import { FashionCheckoutComponent } from './product-pages/fashion/fashion-checkout/fashion-checkout.component';
import { GamingCheckoutComponent } from './product-pages/gaming/gaming-checkout/gaming-checkout.component';
import { CheckoutComponent } from './product-pages/crypto/checkout/checkout.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomePageComponent } from './home-page/home-page.component';
import { CryptoComponent } from './product-pages/crypto/crypto.component';
import { FashionComponent } from './product-pages/fashion/fashion.component';
import { FlowersComponent } from './product-pages/flowers/flowers.component';
import { GamingComponent } from './product-pages/gaming/gaming.component';
import { storePaths } from './StorePaths';
import { CheckoutGuardService } from './services/checkout-guard.service';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: HomePageComponent,
    data: {
      animation: 'home'
    }
  },
  // {
  //   path: storePaths.gaming,
  //   component: GamingComponent,
  //   data: {
  //     animation: 'gaming'
  //   }
  // },
  // {
  //   path: storePaths.crypto,
  //   component: CryptoComponent,
  //   data: {
  //     animation: 'crypto'
  //   }
  // },
  // {
  //   path: storePaths.fashion,
  //   component: FashionComponent,
  //   data: {
  //     animation: 'fashion'
  //   }
  // },
  {
    path: storePaths.flowers,
    component: FlowersComponent,
    data: {
      animation: 'flowers'
    }
  },
  // {
  //   path: storePaths.crypto + '-checkout',
  //   component: CheckoutComponent,
  //   canActivate: [CheckoutGuardService],
  //   data: {
  //     animation: 'crypto-checkout'
  //   }
  // },
  // {
  //   path: storePaths.gaming + '-checkout',
  //   component: GamingCheckoutComponent,
  //   canActivate: [CheckoutGuardService],
  //   data: {
  //     animation: 'gaming-checkout'
  //   }
  // },
  // {
  //   path: storePaths.fashion + '-checkout',
  //   component: FashionCheckoutComponent,
  //   canActivate: [CheckoutGuardService],
  //   data: {
  //     animation: 'fashion-checkout'
  //   }
  // },
  {
    path: storePaths.flowers + '-checkout',
    component: FlowersCheckoutComponent,
    canActivate: [CheckoutGuardService],
    data: {
      animation: 'flowers-checkout'
    }
  },
  {
    path: 'success',
    component: SuccessComponent,
    canActivate: [SuccessGuardService],
    data: {
      animation: 'success'
    }
  },
  {
    path: 'fail',
    component: FailComponent,
    canActivate: [FailGuardService],
    data: {
      animation: 'fail'
    }
  },
  {
    path: '404',
    component: PageNotFoundComponent,
    data: {
      animation: '404'
    }
  },
  {
    path: '**',
    redirectTo: '/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
