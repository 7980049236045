<div class="payment-details-container">
    <div class="payment-container border p-20">
        <h3 class="h3-responsive">Payment Details <i nz-icon nzType="safety-certificate" nzTheme="twotone"
                [nzTwotoneColor]="'#43bd52'"></i></h3>
        <p class="my-30" *ngIf="cardVisibility">Please choose from one of the saved cards below in order to proceed with
            your payment.</p>

        <!-- cards -->
        <div nz-row *ngIf="cardVisibility" nzJustify="center">
            <div nz-col [nzSpan]="colSize">
                <div class="payment-card card-1 mt-20" (click)="selectPaymentCard(1)">
                    <i class="selected-icon" nz-icon nzType="check-circle" nzTheme="twotone"
                        [nzTwotoneColor]="'#43bd52'"></i>
                    <div class="chip-wrapper ">
                        <img src="../../../assets/sections/checkout/chip.png" class="img-fluid" alt="">
                    </div>
                    <div class="card-wrapper mt-20">
                        {{predefinedCards[0].label}}
                    </div>
                    <div class="expiry-wrapper">
                        {{predefinedCards[0].month}}/{{predefinedCards[0].year}} <label
                            class="ml-30">{{predefinedCards[0].cvv}}</label>
                    </div>
                    <div class="img-wrapper">
                        <img src="../../../assets/sections/checkout/master.png" alt="" class="img-fluid">
                    </div>
                </div>
            </div>
            <div nz-col [nzSpan]="colSize">
                <div class="payment-card card-2 mt-20" (click)="selectPaymentCard(2)">
                    <i class="selected-icon" nz-icon nzType="check-circle" nzTheme="twotone"
                        [nzTwotoneColor]="'#43bd52'"></i>
                    <div class="chip-wrapper">
                        <img src="../../../assets/sections/checkout/chip.png" class="img-fluid" alt="">
                    </div>
                    <div class="card-wrapper mt-20">
                        {{predefinedCards[1].label}}
                    </div>
                    <div class="expiry-wrapper">
                        {{predefinedCards[1].month}}/{{predefinedCards[1].year}} <label
                            class="ml-30">{{predefinedCards[1].cvv}}</label>
                    </div>
                    <div class="img-wrapper">
                        <img src="../../../assets/sections/checkout/visa3.png" alt="" class="img-fluid">
                    </div>
                </div>
            </div>
        </div>
        <div nz-row *ngIf="cardVisibility && !cryptoPage">
            <div nz-col [nzSpan]="colSize">
                <div class="payment-card card-1 mt-30" (click)="selectPaymentCard(3)">
                    <i class="selected-icon" nz-icon nzType="check-circle" nzTheme="twotone"
                        [nzTwotoneColor]="'#43bd52'"></i>
                    <div class="chip-wrapper ">
                        <img src="../../../assets/sections/checkout/chip.png" class="img-fluid" alt="">
                    </div>
                    <div class="card-wrapper mt-20">
                        {{predefinedCards[2].label}}
                    </div>
                    <div class="expiry-wrapper">
                        {{predefinedCards[2].month}}/{{predefinedCards[2].year}} <label
                            class="ml-30">{{predefinedCards[2].cvv}}</label>
                    </div>
                    <div class="img-wrapper">
                        <img src="../../../assets/sections/checkout/master.png" alt="" class="img-fluid">
                    </div>
                </div>
            </div>
            <div nz-col [nzSpan]="colSize">
                <div class="payment-card card-2 mt-30" (click)="selectPaymentCard(4)">
                    <i class="selected-icon" nz-icon nzType="check-circle" nzTheme="twotone"
                        [nzTwotoneColor]="'#43bd52'"></i>
                    <div class="chip-wrapper">
                        <img src="../../../assets/sections/checkout/chip.png" class="img-fluid" alt="">
                    </div>
                    <div class="card-wrapper mt-20">
                        {{predefinedCards[3].label}}
                    </div>
                    <div class="expiry-wrapper">
                        {{predefinedCards[3].month}}/{{predefinedCards[3].year}} <label
                            class="ml-30">{{predefinedCards[3].cvv}}</label>
                    </div>
                    <div class="img-wrapper">
                        <img src="../../../assets/sections/checkout/visa3.png" alt="" class="img-fluid">
                    </div>
                </div>
            </div>
        </div>
        <!-- cards -->
        <!-- payments -->
        <div nz-row *ngIf="!cardVisibility" class="flowers-row">
            <div nz-col [nzSpan]="24">
                <nz-radio-group [(ngModel)]="radioValue" (ngModelChange)="changPaymentMethod()">
                    <div class="payment-wrapper" *ngFor="let mode of paymentModeArray" style="margin-bottom:10px;">
                        <label nz-radio [nzValue]="mode.value" class="payment-text-wrapper">{{mode.name}} </label>
                        <div class="payment-img-wrapper">
                            <!-- PayPal Logo -->
                            <table *ngIf="mode.value==='PAYPAL'" border="0" cellspacing="0" align="center">
                                <tr>
                                    <td align="center">
                                        <a href="https://www.paypal.com/uk/webapps/mpp/paypal-popup"
                                            title="How PayPal Works" (click)="paypalIconClick()"><img
                                                src="https://www.paypalobjects.com/webstatic/mktg/Logo/pp-logo-100px.png"
                                                border="0" alt="PayPal Logo">
                                        </a>
                                    </td>
                                </tr>
                            </table>
                            <!-- PayPal Logo -->
                            <img *ngIf="mode.value !=='PAYPAL'" [src]="mode.logo" alt="" class="img-fluid"
                                style="width: 100%;">
                        </div>
                    </div>
                </nz-radio-group>

                <div class="paymentDetails">
                    <p class="mt-10">CelerisPay gateway for a quick, safe and secure transaction.</p>
                </div>
            </div>
        </div>
        <!-- payments -->
    </div>
</div>