<div class="card collection-card z-depth-1-half" (click)="onClickHandler()">
  <!-- Card image -->
  <div class="view zoom">
    <img [src]="imgSrc ? imgSrc : '../../assets/sections/crypto.jpg'" class="img-fluid" alt="">
    <div class="stripe" [ngClass]="stripeTheme">
      <a>
        <p>{{storeTypeName}}
          <i nz-icon nzType="right" nzTheme="outline"></i>
        </p>
      </a>
    </div>
  </div>
  <!-- Card image -->
</div>
