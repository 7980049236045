import { BreakpointObserver } from '@angular/cdk/layout';
import { OrderService } from 'src/app/services/checkout.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzSizeLDSType } from 'ng-zorro-antd/core/types';
import { Subscription } from 'rxjs/internal/Subscription';
import { trigger, state, style, transition, animate, keyframes, group } from '@angular/animations';


@Component({
  selector: 'app-address-detail',
  templateUrl: './address-detail.component.html',
  styleUrls: ['./address-detail.component.less'],
  animations: [trigger('slideDownUp', [
    transition(':enter', [style({ height: 0 }), animate(400)]),
    transition(':leave', [animate(400, style({ height: 0 }))]),
  ])
  ]
})
export class AddressDetailComponent implements OnInit, OnDestroy {

  nestedColSize = 12;
  inputSize: NzSizeLDSType = 'large';
  billingForm!: FormGroup;
  shippingForm!: FormGroup;
  isAddressDifferent = false;
  shouldCheckboxVisible!: boolean;
  formNames = { billing: 'billing', shipping: 'shipping' };
  addressObject;

  private smbreakPointSubscription: Subscription;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private fb: FormBuilder,
    private orderService: OrderService
  ) {
    this.smbreakPointSubscription = this.breakpointObserver.observe('(max-width:767px)').subscribe(result => {
      this.nestedColSize = result.matches ? 24 : 12;
    });
    this.addressObject = this.orderService.addressObject;
  }

  groupShippingForm(): void {
    this.shippingForm = this.fb.group({
      firstname: [, [Validators.required]],
      lastname: [, [Validators.required]],
      addressline1: [, [Validators.required]],
      addressline2: [, [Validators.required]],
      mobileno: [, [Validators.required]],
      email: [, [Validators.required]],
      city: [, [Validators.required]],
      state: [, [Validators.required]],
      country: [, [Validators.required]],
      zip: [, [Validators.required]]
    });
  }
  groupBillingForm(): void {
    this.billingForm = this.fb.group({
      firstname: [this.addressObject.firstName, [Validators.required]],
      lastname: [this.addressObject.lastName, [Validators.required]],
      addressline1: [this.addressObject.addressLine1, [Validators.required]],
      addressline2: [this.addressObject.addressLine2, [Validators.required]],
      mobileno: [this.addressObject.mobileNo, [Validators.required]],
      email: [this.addressObject.emailId, [Validators.required]],
      city: [this.addressObject.city, [Validators.required]],
      state: [this.addressObject.state, [Validators.required]],
      country: [this.addressObject.country, [Validators.required]],
      zip: [this.addressObject.zip, [Validators.required]]
    });
  }
  updateForm(event: any, formName: string, key: string): void {
    const value = event.target.value;
    if (formName === this.formNames.billing) {
      this.billingForm.get(key)?.setValue(value);
      this.orderService.addressObject = this.billingForm.getRawValue();
    }
    else if (formName === this.formNames.shipping) {
      this.shippingForm.get(key)?.setValue(value);
    }
  }

  checkAddressDifference(): void {
    // tslint:disable-next-line: no-unused-expression
    !this.isAddressDifferent ? this.shippingForm.reset() : null;
  }

  ngOnInit(): void {
    this.groupBillingForm();
    this.groupShippingForm();
    this.shouldCheckboxVisible = this.orderService.isCheckboxVisible ? true : false;
    // tslint:disable-next-line: forin
    for (const key in this.billingForm.controls) {
      this.billingForm.controls[key].markAsDirty();
    }
  }

  ngOnDestroy(): void {
    if (this.smbreakPointSubscription) {
      this.smbreakPointSubscription.unsubscribe();
    }
  }
}
