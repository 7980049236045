import { CurrencyService } from './currency.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface PaymentObj {
  customer: any;
  transaction: any;
  url: any;
  paymentDetail?: any;
  '3DSecure'?: any;
}

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  readonly IP = window.location.origin;
  readonly PAYMENT_URL = (window as any).whitelabelConfig.PAYMENT_URL;
  public paymentType = 'HPP';
  constructor(
    private http: HttpClient,
    private currencyService: CurrencyService,
  ) { }

  prepareRequestObject(paymentDetails: any): object {
    const addressObj = paymentDetails.address;
    const orderObj = paymentDetails.order;
    const cardObj = paymentDetails.card;
    const paymentObject: PaymentObj = {
      customer: { id: 'cust001', ...addressObj },
      transaction: {
        txnReference: '',
        currencyCode: this.currencyService.selectedCurrency,
        txnAmount: orderObj.price,
      },
      url: {
        successURL: this.IP + '/success',
        failURL: this.IP + '/fail',
        cancelURL: this.IP + '/fail',
        iframe: false,
      }
    };

    if (paymentDetails.apiType !== 'HPP' && paymentDetails.apiType !== 'plugin') {
      paymentObject.paymentDetail = {
        cvv: cardObj.cvv,
        cardNumber: cardObj.cardNumber,
        cardHolderName: 'John Doe',
        expiryMonth: cardObj.month,
        expiryYear: cardObj.year,
      };
      paymentObject['3DSecure'] = {
        timezone: 330,
        browserColorDepth: 24,
        browserLanguage: 'en-GB',
        browserScreenHeight: 1080,
        browserScreenWidth: 1920,
        os: 'windows',
        browserAcceptHeader: 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*',
        userAgent: 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/81.0.4044.138 Safari/537.36',
        browserJavascriptEnabled: false,
        browserJavaEnabled: true,
        acceptContent: 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3',
        browserIP: '192.1.1.1',
        challengeIndicator: '03',
        challengeWindowSize: '02'
      };
    }
    // console.log(paymentObject);

    let requestObject;

    if (paymentDetails.mode) {
      requestObject = new HttpParams()
        .set('payLoad', btoa(JSON.stringify(paymentObject)))
        .set('apiType', paymentDetails.apiType)
        .set('defaultIntegration', (window as any).whitelabelConfig.defaultIntegration).
        set('mode', paymentDetails.mode);
    }
    else {
      requestObject = new HttpParams()
        .set('payLoad', btoa(JSON.stringify(paymentObject)))
        .set('apiType', paymentDetails.apiType)
        .set('defaultIntegration', (window as any).whitelabelConfig.defaultIntegration);
    }
    return requestObject;
  }

  doPayment(paymentDetails: any): Observable<any> {
    localStorage.setItem('failAllowed', 'true');
    localStorage.setItem('successAllowed', 'true');
    const requestObject = this.prepareRequestObject(paymentDetails);
    // console.log(requestObject);

    return this.http.post(this.PAYMENT_URL, requestObject, {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    });
  }

  getTransactionStatus(txnReference: string): Observable<any> {
    const requestObject = new HttpParams().set('txnReference', txnReference ? txnReference : 'E17C4952-5200-4D2C-A8CB-2D92460321B5').set('apiType', 'status');

    return this.http.post(this.PAYMENT_URL, requestObject, {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    });
  }
}
