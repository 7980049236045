import { CurrencyService } from './../../services/currency.service';
import { storePaths } from 'src/app/StorePaths';
import { SwitchThemeService } from 'src/app/services/switch-theme.service';
import { Component, EventEmitter, OnInit, Output, ViewEncapsulation, OnDestroy, Input } from '@angular/core';
import { Order, OrderService } from 'src/app/services/checkout.service';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.less'],
  encapsulation: ViewEncapsulation.None,

})
export class OrderDetailsComponent implements OnInit {

  orderObject!: Order;
  buttonStylePayPal: object;
  buttonStyle: object;
  currency = 'EUR';

  constructor(
    private orderService: OrderService,
    private themeService: SwitchThemeService,
    private currencyService: CurrencyService,
  ) {
    const buttonColor = '#ffc439';
    const buttonTextColor = '#111';
    this.buttonStylePayPal = { backgroundColor: buttonColor, borderColor: buttonColor, color: buttonTextColor };
    const buttonStyle = (window as any).whitelabelConfig?.buttonColor || '#43bd52';
    const buttonTextStyle = (window as any).whitelabelConfig?.buttonTextColor || '#f2f8f9';
    this.buttonStyle = { backgroundColor: buttonStyle, borderColor: buttonStyle, color: buttonTextStyle };
  }

  // @Input() paymentType = '';
  @Output() cryptoFinalPayment = new EventEmitter<object>();
  @Output() gamingFinalPayment = new EventEmitter<object>();
  @Output() fashionFinalPayment = new EventEmitter<object>();
  @Output() flowersFinalPayment = new EventEmitter<object>();

  ngOnInit(): void {
    this.getOrderServiceValues();
    this.currency = this.currencyService.selectedCurrency;
  }

  getOrderServiceValues(): void {
    this.orderObject = this.orderService.orderObject;
  }

  finalPayment(): void {
    this.getOrderServiceValues();
    // console.log(this.themeService.currentTheme, this.orderObject, JSON.stringify(this.orderObject));
    localStorage.setItem('orderObject', JSON.stringify(this.orderObject));
    localStorage.setItem('addressObject', JSON.stringify(this.orderService.addressObject));
    if (this.themeService.currentTheme === storePaths.crypto) {
      this.cryptoFinalPayment.emit({
        address: this.orderService.addressObject,
        order: this.orderObject,
        card: this.orderService.selectedCard
      });
    }
    else if (this.themeService.currentTheme === storePaths.gaming) {
      this.gamingFinalPayment.emit({
        address: this.orderService.addressObject,
        order: this.orderObject,
        card: this.orderService.selectedCard
      });
    }
    else if (this.themeService.currentTheme === storePaths.fashion) {
      this.fashionFinalPayment.emit({
        address: this.orderService.addressObject,
        order: this.orderObject,
        card: this.orderService.selectedCard
      });
    }
    else if (this.themeService.currentTheme === storePaths.flowers) {
      this.flowersFinalPayment.emit({
        address: this.orderService.addressObject,
        order: this.orderObject,
      });
    }
  }
}
