import { Router } from '@angular/router';
import { storePaths } from 'src/app/StorePaths';
import { Cards } from 'src/app/Cards';
import { SwitchThemeService } from 'src/app/services/switch-theme.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, OnDestroy, ElementRef, Input, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { OrderService, PaymentCard } from 'src/app/services/checkout.service';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.less'],
  animations: [trigger('slideDownUp', [
    transition(':enter', [style({ height: 0 }), animate(300)]),
    transition(':leave', [animate(300, style({ height: 0 }))]),
  ])
  ]
})
export class PaymentDetailsComponent implements OnInit, OnDestroy, AfterViewInit {

  colSize = 12;
  cryptoPage = false;
  predefinedCards: PaymentCard[] = [];
  allCards: any;
  showPaymentDetails = true;
  private smBreakpointSubscription: Subscription;
  @Input() cardVisibility!: boolean;

  @Output() paymentType: EventEmitter<string> = new EventEmitter();
  payment = 'wlpayments';
  paymentModeArray: { name: string, value: string, logo: string }[];

  breakPointsObj = {
    sm: '(max-width: 600px)',
    md: '(max-width: 768px)',
    lg: '(max-width: 1024px)',
    xl: '(max-width: 1600px)'
  };
  // companyName: string;
  // whitelabelIcon: string;
  // whitelabelIconC: string;
  radioValue: string;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private eleRef: ElementRef,
    private orderService: OrderService,
    private themeService: SwitchThemeService,
    private router: Router,
  ) {
    const whiteLabelConfig = (window as any).whitelabelConfig;
    this.paymentModeArray = [
      { name: 'Pay with PayPal', logo: 'assets/mode/paypal.svg', value: 'PAYPAL' },
      { name: 'Pay with CelerisPay', logo: whiteLabelConfig?.logo || '../../../favicon.ico', value: 'HPP' }
    ];
    this.radioValue = this.paymentModeArray[0].value;
    this.smBreakpointSubscription = this.breakpointObserver.observe(
      [this.breakPointsObj.sm, this.breakPointsObj.md, this.breakPointsObj.lg, this.breakPointsObj.xl]
    ).
      subscribe(result => {
        if (result.breakpoints[this.breakPointsObj.xl] && !result.breakpoints[this.breakPointsObj.lg]) {
          this.colSize = 24;
        }
        else if (result.breakpoints[this.breakPointsObj.lg] && !result.breakpoints[this.breakPointsObj.md]) {
          this.colSize = 8;
        }
        else if (result.breakpoints[this.breakPointsObj.md] && !result.breakpoints[this.breakPointsObj.sm]) {
          this.colSize = 11;
        }
        else if (result.breakpoints[this.breakPointsObj.sm]) {
          this.colSize = 24;
        }
        else {
          this.colSize = 12;
        }
      });
  }

  ngOnInit(): void {
    const theme = this.themeService.currentTheme;
    if (theme.length !== 0 && theme === storePaths.crypto) {
      this.predefinedCards = Cards.crypto;
    }
    else if (theme.length !== 0 && theme === storePaths.gaming) {
      this.predefinedCards = Cards.gaming;
    }
    else if (theme.length !== 0 && theme === storePaths.fashion) {
      this.predefinedCards = Cards.fashion;
    }
    this.changPaymentMethod();
    if (this.router.url === '/crypto-checkout') {
      this.cryptoPage = true;
    }
  }

  ngAfterViewInit(): void {
    if (this.cardVisibility) {
      this.getPredefinedCards();
    }
  }

  changPaymentMethod(): void {
    this.paymentType.emit(this.radioValue);
  }

  paypalIconClick(): false {
    window.open('https://www.paypal.com/uk/webapps/mpp/paypal-popup', 'WIPaypal', 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1060, height=700');
    return false;
  }

  getPredefinedCards(): void {
    this.allCards = this.eleRef.nativeElement.querySelectorAll('.payment-card');
    this.allCards[0].classList.add('selected-card');
    this.orderService.selectedCard = this.predefinedCards[0];
  }

  selectPaymentCard(index: number): void {
    this.allCards.forEach((card: { classList: { remove: (arg0: string) => void; }; }) => {
      card.classList.remove('selected-card');
    });
    this.allCards[index - 1].classList.add('selected-card');

    this.orderService.selectedCard = this.predefinedCards[index - 1];
    // console.log(this.orderService.selectedCard);
  }

  togglePaymentDetails(): void {
    // console.log('toggle payment details');
    this.showPaymentDetails = !this.showPaymentDetails ? false : true;
  }

  ngOnDestroy(): void {
    if (this.smBreakpointSubscription) {
      this.smBreakpointSubscription.unsubscribe();
    }
  }
}
