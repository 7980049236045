import { Component, OnDestroy, OnInit } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';
import { StoreTypeInterface } from './store-type/store-type.component';
import { SwitchThemeService } from '../services/switch-theme.service';
import { storePaths } from '../StorePaths';
import { Title } from '@angular/platform-browser';
import { pageTitles } from '../PageTitles';


@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.less']
})
export class HomePageComponent implements OnInit, OnDestroy {

  gutterSize = 16;
  colSize = 6;

  private breakPointSubscription: Subscription;

  sections: Array<StoreTypeInterface> = [
    // {
    //   stripeTheme: 'dark',
    //   storeTypeName: 'Crypto',
    //   imgSrc: '../../assets/sections/crypto.jpg',
    //   redirectUrl: storePaths.crypto,
    // },
    // {
    //   stripeTheme: 'light',
    //   storeTypeName: 'Gaming',
    //   imgSrc: '../../assets/sections/gaming.jpg',
    //   redirectUrl: storePaths.gaming,
    // },
    // {
    //   stripeTheme: 'dark',
    //   storeTypeName: 'Fashion',
    //   imgSrc: '../../assets/sections/fashion.jpg',
    //   redirectUrl: storePaths.fashion,
    // },
    {
      stripeTheme: 'light',
      storeTypeName: 'Flowers',
      imgSrc: '../../assets/sections/flowers.jpg',
      redirectUrl: storePaths.flowers,
    },
  ];

  constructor(
    private breakpointObserver: BreakpointObserver,
    private switchThemeService: SwitchThemeService,
    private titleService: Title
  ) {
    this.breakPointSubscription = this.breakpointObserver.observe('(max-width: 768px)').subscribe(result => {
      this.gutterSize = result.matches ? 16 : 48;
      this.colSize = result.matches ? 12 : 6;
    });
  }

  ngOnInit(): void {
    this.switchThemeService.clearTheme();
    this.titleService.setTitle(pageTitles.base);
  }

  ngOnDestroy(): void {
    if (this.breakPointSubscription) {
      this.breakPointSubscription.unsubscribe();
    }
  }

}
